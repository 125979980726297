import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function Flower({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        d="M246.001 76.3954L251.208 81.8013L256.61 76.5907C269.058 64.5841 282.854 55.2979 297.059 49.4179C319.219 40.2471 343.772 38.9472 364.75 51.0591L364.75 51.0593C385.73 63.1713 396.882 85.0863 400.018 108.861L400.018 108.861C402.029 124.105 400.884 140.695 396.71 157.481L394.899 164.764L402.184 166.57C419.022 170.745 434.243 177.996 446.687 187.672C466.144 202.801 479.5 224.57 479.5 249.814C479.5 274.921 466.286 296.594 446.999 311.711C434.952 321.153 420.27 328.31 404.023 332.585L396.705 334.511L398.696 341.811C403.117 358.02 404.259 374.313 402.106 389.468C398.657 413.729 386.494 436.011 364.753 448.562L364.752 448.563C342.89 461.186 317.36 460.501 294.529 451.218C279.927 445.278 266.038 435.721 254.004 423.226L248.797 417.82L243.395 423.03C230.944 435.04 217.151 444.326 202.943 450.207C180.784 459.376 156.229 460.676 135.25 448.565C114.272 436.452 103.121 414.538 99.9839 390.765C97.9724 375.519 99.1171 358.93 103.291 342.146L105.102 334.862L97.8177 333.056C80.979 328.881 65.7569 321.63 53.3142 311.955C33.8573 296.824 20.5 275.056 20.5 249.814C20.5 224.706 33.7159 203.031 53.0031 187.914L48.3765 182.011L53.0032 187.914C65.0509 178.471 79.7325 171.314 95.9799 167.039L103.299 165.114L101.307 157.812C96.8854 141.604 95.7427 125.311 97.8966 110.156C101.345 85.8939 113.507 63.6115 135.251 51.0578L131.736 44.9695L135.251 51.0578C157.113 38.4358 182.643 39.1196 205.474 48.4048L208.3 41.4574L205.474 48.4048C220.076 54.343 233.967 63.9002 246.001 76.3954ZM250 147.511C193.501 147.511 147.7 193.312 147.7 249.811C147.7 306.309 193.501 352.111 250 352.111C306.498 352.111 352.3 306.309 352.3 249.811C352.3 193.312 306.498 147.511 250 147.511ZM250 209.911C272.037 209.911 289.9 227.774 289.9 249.811C289.9 271.848 272.037 289.711 250 289.711C227.963 289.711 210.1 271.848 210.1 249.811C210.1 227.774 227.963 209.911 250 209.911Z"
        strokeWidth="15"
      />
    </motion.svg>
  );
}
