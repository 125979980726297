import React from "react";
import Layout from "../components/Layout";
import Meta from "../components/Meta";
import HeroSection from "../components/HeroSection";
import * as styles from "../styles/services.module.css";
import * as heroStyles from "../styles/hero-section.module.css";
import Card from "../components/Card";
import Pencil from "../components/icons/Pencil";
import HandBulb from "../components/icons/HandBulb";
import Network from "../components/icons/Network";
import Flower from "../components/icons/Flower";
import LinearReveal from "../components/animation/LinearReveal";

export default function SAP() {
  const cardDataRow1 = [
    {
      icon: <Pencil />,
      title: "Customization ",
      content: (
        <p>
          Our team develops a curated strategy to help you customize the SAP solution as
          per your organization's unique needs. We support the integration of the SAP
          solution into your existing IT environment for seamless business operations and
          minimal disruption to your business.
        </p>
      ),
    },
    {
      icon: <HandBulb />,
      title: "Managed Services",
      content: (
        <p>
          Our team provides all-around SAP managed services that include application
          management and support to help you gain the most from our proven methodologies.
          Nsight assures best-in-class support to improve synchronization, seamless
          business operations, and rapid growth of your business.
        </p>
      ),
    },
  ];
  const cardDataRow2 = [
    {
      icon: <Network />,
      title: "Integration Services",
      content: (
        <p>
          Our team is vastly experienced in SAP integration technology with in-depth
          knowledge of interface tools. We provide seamless integration, architecture
          design, consolidation, and migration, focusing on reducing your business
          complexities and ensuring an updated technology stack for your organization.
        </p>
      ),
    },
    {
      icon: <Flower />,
      title: "SAP Fiori ",
      content: (
        <p>
          DBT helps you leverage SAP Fiori, a UX suite that enables organizations to
          personalize and simplify the user experience (UX) using intuitive design
          principles. The design language is used in SAP applications like SAP S/4HANA,
          SAP Analytics Cloud, and SAP Ariba.{" "}
        </p>
      ),
    },
  ];
  return (
    <Layout>
      <Meta
        title="SAP S/4 HANA - DBT"
        description="SAP - Accelerating Digital Transformation"
      />
      <HeroSection
        title="SAP S/4 HANA"
        titleClass={"text-primary" + " " + heroStyles.heroTitle}
        description="Accelerating Digital Transformation"
        descClass={"has-text-white-bis " + heroStyles.heroDesc}
        imageClass={heroStyles.sapImage + " " + heroStyles.heroData}
        divClass={heroStyles.divClass}
      />{" "}
      <section>
        <LinearReveal>
          <h1 className={"text-primary has-text-centered " + styles.heading}>
            Transformation with SAP Powered by DBT
          </h1>
        </LinearReveal>{" "}
        <LinearReveal>
          <div className="px-5" style={{ maxWidth: "1000px", margin: "auto" }}>
            <p className={styles.description}>
              SAP offers innovative, revolutionary, and world-class solutions to
              re-imagine new possibilities for your business. DBT helps drive efficiency
              and maximum value from the SAP technology offering expertise in the SAP
              product portfolio focused on Sales & Contract management. We recommend the
              best strategy, system design, proof of concept, end-to-end implementation,
              and managed services. Our certified SAP consultants help you modernize IT,
              enhance security, optimize business processes, and accelerate your digital
              transformation journey.
            </p>
          </div>
        </LinearReveal>
        <LinearReveal>
          <h2
            className={
              "sub-title text-heading has-text-centered " + styles.sectionHeading
            }
          >
            Our SAP Services
          </h2>
        </LinearReveal>
        <LinearReveal>
          <div className="columns mt-4">
            {cardDataRow1.map((card, index) => (
              <div key={index} className="column is-flex is-justify-content-center mb-4">
                <Card
                  maxWidth={"400px"}
                  icon={card.icon}
                  title={card.title}
                  titleClass="text-heading has-text-centered"
                  cardStyle={{ paddingTop: "30px" }}
                  content={card.content}
                  contentClass="pt-2 text-description"
                />
              </div>
            ))}{" "}
          </div>
        </LinearReveal>
        <LinearReveal>
          <div className="columns">
            {cardDataRow2.map((card, index) => (
              <div key={index} className="column is-flex is-justify-content-center mb-4">
                <Card
                  maxWidth={"400px"}
                  icon={card.icon}
                  title={card.title}
                  titleClass="text-heading has-text-centered"
                  cardStyle={{ paddingTop: "30px" }}
                  content={card.content}
                  contentClass="pt-2 text-description"
                />
              </div>
            ))}{" "}
          </div>
        </LinearReveal>
      </section>
    </Layout>
  );
}
