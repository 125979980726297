import React, { useEffect, useRef } from "react";
import { motion, useAnimation, useInView } from "framer-motion";

export default function HandBulb({ dimension = "150" }) {
  const controls = useAnimation();
  const iconRef = useRef(null);
  const isInView = useInView(iconRef, { once: true });
  useEffect(() => {
    if (isInView) {
      controls.start("visible");
    }
  }, [isInView]);

  const iconPath = {
    hidden: {
      pathLength: 0,
      stroke: "var(--secondary)",
    },
    visible: {
      pathLength: 1,
      stroke: [
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--secondary)",
        "var(--primary)",
      ],
      transition: {
        duration: 2,
        ease: "easeInOut",
      },
      transitionEnd: {
        fill: "var(--primary)",
      },
    },
  };
  return (
    <motion.svg
      width={dimension}
      height={dimension}
      viewBox="0 0 500 500"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ref={iconRef}
      initial="hidden"
      animate={controls}
    >
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M267.757 440.775C263.017 440.775 259.174 436.933 259.174 432.192C259.174 427.451 263.016 423.608 267.757 423.608C274.309 423.608 279.642 418.275 279.642 411.724C279.642 405.172 274.309 399.841 267.757 399.841L215.98 399.931C215.976 399.931 215.97 399.931 215.965 399.931C214.586 399.931 213.227 399.599 212.005 398.963L167.326 375.727L119.47 375.727C114.729 375.727 110.887 371.884 110.887 367.143C110.887 362.402 114.729 358.56 119.47 358.56L169.425 358.56C170.804 358.56 172.162 358.892 173.385 359.528L218.056 382.76L267.742 382.674C283.776 382.674 296.808 395.705 296.808 411.723C296.808 427.742 283.776 440.775 267.757 440.775Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.261 500H220.947C219.891 500 218.843 499.806 217.857 499.424L140.452 469.56H120.066C115.325 469.56 111.482 465.718 111.482 460.977C111.482 456.236 115.325 452.393 120.066 452.393H142.051C143.107 452.393 144.154 452.587 145.141 452.968L222.545 482.833L248.43 482.833C254.515 480.206 266.093 476.006 281.525 470.406C317.502 457.352 371.871 437.625 390.703 424.816C395.861 421.307 396.555 414.717 394.241 410.625C390.351 403.743 379.066 403.553 364.794 410.135C364.56 410.243 364.321 410.34 364.077 410.427L280.207 440.28C279.284 440.608 278.31 440.776 277.329 440.776L197.442 440.776C192.701 440.776 188.859 436.933 188.859 432.192C188.859 427.451 192.701 423.608 197.442 423.608L275.847 423.609L357.966 394.379C385.397 381.869 402.403 390.182 409.185 402.175C416.245 414.662 412.369 430.842 400.359 439.012C379.749 453.032 326.362 472.402 287.381 486.545C271.934 492.151 258.591 496.992 253.906 499.189C252.766 499.723 251.521 500 250.261 500Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M119.47 490.819H53.5837C48.8425 490.819 45 486.976 45 482.235V346.232C45 341.492 48.8427 337.648 53.5837 337.648H119.47C124.211 337.648 128.054 341.491 128.054 346.232V482.236C128.054 486.976 124.212 490.819 119.47 490.819ZM62.1675 473.652H110.887V354.816H62.1675V473.652Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M324.752 289.782H231.651C226.91 289.782 223.067 285.939 223.067 281.198C223.067 240.231 212.445 226.824 202.171 213.857C192.526 201.684 182.553 189.097 182.553 159.307C182.553 106.567 225.461 63.6582 278.202 63.6582C330.942 63.6582 373.85 106.567 373.85 159.307C373.85 189.096 363.877 201.684 354.231 213.857C343.959 226.824 333.335 240.231 333.335 281.198C333.334 285.939 329.492 289.782 324.752 289.782ZM240.079 272.616H316.321C317.822 232.169 329.974 216.829 340.775 203.197C349.661 191.98 356.681 183.12 356.681 159.308C356.681 116.033 321.475 80.8275 278.2 80.8275C234.925 80.8275 199.718 116.034 199.718 159.308C199.718 183.12 206.738 191.98 215.625 203.197C226.428 216.829 238.579 232.168 240.079 272.616Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M278.201 50.9615C273.461 50.9615 269.617 47.1189 269.617 42.3777V8.58381C269.617 3.84266 273.46 0 278.201 0C282.942 0 286.785 3.84266 286.785 8.58381V42.3777C286.785 47.1189 282.942 50.9615 278.201 50.9615Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.196 90.3101C370.999 90.3101 368.803 89.4719 367.126 87.7953C363.774 84.4422 363.774 79.0082 367.126 75.6563L391.023 51.7605C394.377 48.4075 399.81 48.4097 403.162 51.7605C406.514 55.1136 406.514 60.5476 403.162 63.8996L379.265 87.7953C377.588 89.472 375.391 90.3101 373.196 90.3101Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M446.339 185.304H412.545C407.805 185.304 403.961 181.462 403.961 176.721C403.961 171.98 407.804 168.137 412.545 168.137H446.339C451.079 168.137 454.922 171.979 454.922 176.721C454.922 181.462 451.079 185.304 446.339 185.304Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M397.092 304.195C394.894 304.195 392.698 303.358 391.022 301.681L367.126 277.786C363.774 274.434 363.774 268.999 367.126 265.647C370.479 262.294 375.912 262.294 379.265 265.647L403.162 289.544C406.514 292.895 406.514 298.331 403.162 301.683C401.485 303.358 399.289 304.195 397.092 304.195Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M159.31 304.195C157.113 304.195 154.916 303.358 153.239 301.682C149.888 298.33 149.888 292.895 153.239 289.543L177.135 265.646C180.487 262.293 185.922 262.293 189.274 265.646C192.626 268.997 192.626 274.433 189.274 277.785L165.378 301.682C163.703 303.358 161.506 304.195 159.31 304.195Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.858 185.304H110.064C105.323 185.304 101.48 181.462 101.48 176.721C101.48 171.98 105.323 168.137 110.064 168.137H143.858C148.599 168.137 152.442 171.979 152.442 176.721C152.442 181.462 148.598 185.304 143.858 185.304Z"
      />
      <motion.path
        variants={iconPath}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.205 90.3099C181.009 90.3099 178.812 89.4716 177.135 87.795L153.239 63.8994C149.888 60.5464 149.888 55.1124 153.239 51.7603C156.591 48.4087 162.026 48.4087 165.378 51.7603L189.274 75.6561C192.626 79.009 192.626 84.4431 189.274 87.795C187.599 89.4718 185.403 90.3099 183.205 90.3099Z"
      />
      <motion.path
        variants={iconPath}
        d="M314.625 297H240.375C237.093 297 233.945 298.304 231.625 300.625C229.304 302.945 228 306.093 228 309.375C228 312.657 229.304 315.805 231.625 318.125C233.945 320.446 237.093 321.75 240.375 321.75H314.625C317.907 321.75 321.055 320.446 323.375 318.125C325.696 315.805 327 312.657 327 309.375C327 306.093 325.696 302.945 323.375 300.625C321.055 298.304 317.907 297 314.625 297Z"
      />
      <motion.path
        variants={iconPath}
        d="M302 329H254C250.817 329 247.765 330.264 245.515 332.515C243.264 334.765 242 337.817 242 341C242 344.183 243.264 347.235 245.515 349.485C247.765 351.736 250.817 353 254 353H302C305.183 353 308.235 351.736 310.485 349.485C312.736 347.235 314 344.183 314 341C314 337.817 312.736 334.765 310.485 332.515C308.235 330.264 305.183 329 302 329Z"
      />
    </motion.svg>
  );
}
